<template>
  <div class="project-settings-wrap">
    <section class="project-content">
      <router-view />
    </section>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
  .project-settings-wrap {
    width: 100%;
    min-height: 100%;
  }
</style>
